import React, { useState } from 'react'
import { BlockDivider, DropShadow, ItemContent, LoadingBlock, PurchaseButton, UIView } from '../../../components/atoms'
import { Carousel, ItemHeader, ItemPrice, MerchSizes, ShopItem, SidebarHeader } from '../../../components/molecules'
import { pluckMetaValueFromShopifyProduct, getShopifyGatsbyImageData } from '../../../utils'
import { ShopItemType } from '../../../types'
import styled from 'styled-components'
import Widths from '../../../theme/widths'

const RelatedItemsContainer = styled.div`
  display: grid;
  margin: 0;
  column-gap: 16px;
  row-gap: 16px;

  ${Widths.views.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }

  ${Widths.views.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Seperator = styled.hr`
  height: 12px;
  background: black;
  margin-bottom: 24px;
`

const RelatedItems = styled.section`
  margin-bottom: 32px;
  margin-top: 32px;
`

const RelatedItemsSection: React.FC<{ items: ShopItemType[] }> = ({ items }) => {
  const relatedItems: ShopItemType[] = items.map((shopItem: any): ShopItemType => {
    return {
      slug: shopItem.handle,
      title:
        shopItem.productType === 'BSR Releases'
          ? pluckMetaValueFromShopifyProduct(shopItem, 'artists')
          : pluckMetaValueFromShopifyProduct(shopItem, 'title'),
      subtitle:
        shopItem.productType === 'BSR Releases' ? pluckMetaValueFromShopifyProduct(shopItem, 'title') : undefined,
      gatsbyImageData: getShopifyGatsbyImageData(shopItem),
      productType: shopItem.productType,
      variants: shopItem.variants,
      latest: shopItem.tags.includes('latest'),
      metafields: shopItem.metafields,
      minimumPrice: shopItem.priceRangeV2.minVariantPrice.amount,
      maximumPrice: shopItem.priceRangeV2.maxVariantPrice.amount
    }
  })

  return (
    <RelatedItems>
      <SidebarHeader>Related items</SidebarHeader>
      <Seperator />
      <RelatedItemsContainer>
        {relatedItems.map((shopItem, key) => (
          <ShopItem
            productType={shopItem.productType}
            variants={shopItem.variants}
            slug={shopItem.slug}
            gatsbyImageData={shopItem.gatsbyImageData}
            title={shopItem.title}
            subtitle={shopItem.subtitle}
            availableForSale={shopItem.availableForSale}
            metafields={shopItem.metafields}
            key={key}
            minimumPrice={shopItem.minimumPrice}
            maximumPrice={shopItem.maximumPrice}
          />
        ))}
      </RelatedItemsContainer>
    </RelatedItems>
  )
}
export default RelatedItemsSection
