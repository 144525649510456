import React from 'react'
import { graphql } from 'gatsby'
import { useQuery } from '@apollo/client'

import Layout from '../layout'
import { AbstractItem } from '../components/templates'
import { Carousel, ItemHeader, ItemPrice, SidebarHeader } from '../components/molecules'
import { BlockDivider, ItemContent, PurchaseButton, UIView } from '../components/atoms'
import { pluckMetaValueFromShopifyProduct } from '../utils'
import { STOCK_STATUS_QUERY } from '../fragments'
import RelatedItemsSection from '../components/organisms/RelatedItemsSection'

const Other = ({ data, location }) => {
  const merch = data.shopifyProduct || {}
  const relatedMerch = data.relatedMerch.nodes
  const title = pluckMetaValueFromShopifyProduct(merch, 'title')
  const subtitle = pluckMetaValueFromShopifyProduct(merch, 'subtitle')
  const variant = merch.variants[0]

  const stockStatusQueryResult = useQuery(STOCK_STATUS_QUERY, {
    variables: { handle: data.shopifyProduct.handle }
  })

  return (
    <Layout pageName={merch.title} location={location}>
      <UIView
        desktop={() => (
          <AbstractItem
            left={<Carousel images={merch.images} />}
            middle={
              <>
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={pluckMetaValueFromShopifyProduct(merch, 'description')}
                  itemType={merch.productType.toLowerCase()}
                />
                <ItemContent content={merch.descriptionHtml} />
              </>
            }
            right={
              <>
                <div>
                  <SidebarHeader />
                  <BlockDivider />
                </div>
                <ItemPrice price={variant.price} compareAtPrice={variant.compareAtPrice} />
                {stockStatusQueryResult.data && (
                  <>
                    <PurchaseButton
                      variantId={variant.shopifyId}
                      itemType={merch.productType}
                      soldOut={!stockStatusQueryResult?.data?.productByHandle?.availableForSale}
                    />
                  </>
                )}
              </>
            }
          />
        )}
        mobile={() => (
          <AbstractItem
            left={<Carousel images={merch.images} />}
            middle={
              <>
                <BlockDivider itemType={merch.productType.toLowerCase()} />
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={pluckMetaValueFromShopifyProduct(merch, 'description')}
                  divider={false}
                />
                <ItemPrice price={variant.price} compareAtPrice={variant.compareAtPrice} />
                {stockStatusQueryResult.data && (
                  <>
                    <PurchaseButton
                      variantId={variant.shopifyId}
                      itemType={merch.productType}
                      soldOut={!stockStatusQueryResult?.data?.productByHandle?.availableForSale}
                    />
                  </>
                )}
              </>
            }
            right={<ItemContent content={merch.descriptionHtml} />}
          />
        )}
      />
      <RelatedItemsSection items={relatedMerch} />
    </Layout>
  )
}

export const query = graphql`
  query OtherQuery($pagePath: String!) {
    shopifyProduct: shopifyProduct(handle: { eq: $pagePath }) {
      handle
      title
      productType
      metafields {
        key
        namespace
        value
      }
      variants {
        shopifyId
        price
        title
        compareAtPrice
      }
      images {
        gatsbyImageData(layout: CONSTRAINED, height: 840)
      }
      descriptionHtml
    }
    relatedMerch: allShopifyProduct(
      filter: { productType: { eq: "Other" } }
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        productType
        title
        createdAt
        tags
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        metafields {
          namespace
          key
          value
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  }
`

export default Other
